import React, {ChangeEventHandler} from "react";
import {EditBrandMapBodyFrontend} from "../../pages/brands/components/BrandMapFormValues";
import UploadImagePreview from "../cards/UploadImagePreview";

interface Props {
    formValues: EditBrandMapBodyFrontend;
    onInputChange: (key: keyof EditBrandMapBodyFrontend) => ChangeEventHandler<HTMLInputElement>;
    onImageChange: (key: "autoMintToastBackground") => ChangeEventHandler<HTMLInputElement>;
    onRemoveImage: (key: "autoMintToastBackground") => void;
}

function MerchantBrandMapPostAutoMintToast(props: Props) {
    return (
        <div className="create-map-page_container">
            <div className="create-map-page_container_inner create-map-page_main-promo_title">
                <h5>
                    Post Auto-mint Toast
                </h5>
                <p className="merchant-brand-title-description_content_subtitle">
                    The toast will appear to the user when an NFT is auto-minted to their DevourGo wallet.
                </p>

                <div className="create-map-page_automint-toast">
                    <div className="create-map-page_automint-toast_field">
                        <p>
                            Toast Message
                        </p>
                        <input
                            placeholder="E.g. Your redemption token is now available!"
                            value={props.formValues.autoMintToastMessage}
                            onChange={props.onInputChange("autoMintToastMessage")}
                        />
                    </div>

                    <div className="create-map-page_automint-toast_field">
                        <p>
                            Preview of Toast Background Image
                        </p>
                        <UploadImagePreview
                            <EditBrandMapBodyFrontend>
                            onImageChange={props.onImageChange}
                            onRemoveImage={props.onRemoveImage}
                            image={props.formValues.autoMintToastBackground}
                            fieldName="autoMintToastBackground"
                            optional={true}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MerchantBrandMapPostAutoMintToast;