import {CreateBrandMapBodyFrontend} from "../../pages/brands/components/BrandMapFormValues";
import React, {ChangeEventHandler, ReactElement} from "react";


interface Props {
    onChange: (key: keyof CreateBrandMapBodyFrontend) => ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
    value: string;
}

function MerchantBrandDescription(props: Props): ReactElement {
    return (
        <div className="merchant-brand-title-description">
            <div className="merchant-brand-title-description_content">
                <h5>
                    Description
                    <span className="create-map-page_optional">Optional</span>
                </h5>
                <p className="merchant-brand-title-description_content_subtitle">
                    Provide an overview of your brand's presence.
                </p>
                <textarea
                    placeholder="E.g. Explore our interactive brand map to discover exclusive rewards and experiences at key locations!"
                    value={props.value}
                    onChange={props.onChange("description")}
                />
            </div>
        </div>
    );
}

export default MerchantBrandDescription;