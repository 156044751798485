import React, {ChangeEventHandler, ReactElement, useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {
    Address,
    Asset,
    AssetsApi,
    Brand,
    BrandMap,
    BrandMapAdvertisementBlocksBody,
    BrandMapColorTheme,
    BrandMapStatus,
    BrandsApi,
    Business,
    GetBrandBusinessesResponse,
    LoginAccessPoint,
    OrderDiscount,
} from "@devour/client";
import {useParams} from "react-router";
import {useNavigate} from "react-router-dom";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/metaActions";
import getConfig from "../../utils/getConfig";
import {addURLsToFiles} from "../../utils/renderAssetsHelper";
import CreateBrandMapEditAddressModal from "../../components/modals/CreateBrandMapEditAddressModal";
import BrandeMapSaveDraftSuccessModal from "../../components/modals/BrandeMapSaveDraftSuccessModal";
import BrandMapSavePublishedSuccessModal from "../../components/modals/BrandMapSavePublishedSuccessModal";
import MapCreationPageHeader from "../../components/brands/MapCreationPageHeader";
import generateCrytoSecureString from "../../utils/generateCryptoSecureString";
import {isAsset} from "../../utils/typeGuards";
import {cloneDeep} from "lodash";
import {
    CreateAdvertisementBlockFormData,
    CreateBrandMapBodyFrontend,
    EditBrandMapBodyFrontend
} from "./components/BrandMapFormValues";
import MerchantBrandTitleDescription from "../../components/brands/MerchantBrandTitleDescription";
import MerchantBrandHandleName from "../../components/brands/MerchantBrandHandleName";
import MerchantBrandUploadBannerImage from "../../components/brands/MerchantBrandUploadBannerImage";
import EditBrandMapMainPromoSelection from "./components/EditBrandMapMainPromoSelection";
import EditBrandMapMainPromoTitle from "./components/EditBrandMapMainPromoTitle";
import EditBrandMapMainPromoTagline from "./components/EditBrandMapMainPromoTagline";
import EditBrandMapMainPromoDescription from "./components/EditBrandMapMainPromoDescription";
import EditBrandMapMainPromoImages from "./components/EditBrandMapMainPromoImages";
import EditBrandMapMainPromoCartImages from "./components/EditBrandMapMainPromoCartImages";
import MerchantBrandAdvertisementImages from "../../components/brands/MerchantBrandAdvertisementImages";
import MerchantBrandPageTheme from "../../components/brands/MerchantBrandPageTheme";
import MerchantBrandButtons from "../../components/brands/MerchantBrandButtons";
import MerchantBrandLocationSelection from "../../components/brands/MerchantBrandLocationSelection";
import {ViewMode} from "./EditBrandMapPage";
import BrandMapPreviewMode from "./BrandMapPreviewMode";
import classNames from "classnames";
import MerchantBrandAutoMint from "../../components/brands/MerchantBrandAutoMint";
import MerchantBrandMapPostAutoMintToast from "../../components/brands/MerchantBrandMapPostAutoMintToast";
import BrandMapToastTextColor from "../../components/brands/BrandMapToastTextColor";
import MerchantBrandDescription from "../../components/brands/MerchantBrandDescription";
import MerchantBrandHeaders from "../../components/brands/MerchantBrandHeaders";
import MerchantBrandMapPreAutoMintToast from "../../components/brands/MerchantBrandMapPreAutoMintToast";
import MerchantBrandUserLoginSettings from "../../components/brands/MerchantBrandUserLoginSettings";

const defaultAdvertisementBlock: CreateAdvertisementBlockFormData = {
    image: undefined,
    url: "",
};

const defaultCreateBrandMapForm: CreateBrandMapBodyFrontend = {
    name: "",
    slug: generateCrytoSecureString(["numbers", "lowercase"], 12),
    bannerImage: undefined,
    defaultLocation: undefined,
    businesses: [],
    promos: [],
    mainPromo: "",
    promoHero: undefined,
    promoBackground: undefined,
    promoCartHero: undefined,
    promoCartBackground: undefined,
    promoDescription: "",
    promoTagline: "",
    promoTitle: "",
    colorTheme: BrandMapColorTheme.LIGHT,
    colorBackground: "#000000",
    advertisementBlocks: [cloneDeep(defaultAdvertisementBlock), cloneDeep(defaultAdvertisementBlock), cloneDeep(defaultAdvertisementBlock)],
    defaultBusiness: undefined,
    autoMintContract: "",
    autoMintToastMessage: "",
    autoMintToastBackground: undefined,
    description: "",
    autoMintToastTextColor: BrandMapColorTheme.DARK,
    topHeader: "",
    bottomHeader: "",
    preAutoMintToastMessage: "",
    preAutoMintToastBackground: undefined,
    loginAccessPoint: LoginAccessPoint.PAGE
};

function CreateBrandMapPage(): ReactElement {

    const {brandId} = useParams<{ brandId?: string }>();
    const history = useNavigate();
    const dispatch = useDispatch();
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const [businessesRes, setBusinessesRes] = useState<GetBrandBusinessesResponse>(undefined);
    const [brand, setBrand] = useState<Brand>(undefined);
    const [createBrandMapForm, setCreateBrandMapForm] = useState<CreateBrandMapBodyFrontend>(defaultCreateBrandMapForm);
    const [showAddressModal, setShowAddressModal] = useState(false);
    const [showDraftSuccess, setShowDraftSuccess] = useState(false);
    const [showPublishSuccess, setShowPublishSuccess] = useState(false);
    const [createdBrandMap, setCreatedBrandMap] = useState<BrandMap>(undefined);
    const [viewMode, setViewMode] = useState<ViewMode>(ViewMode.EDITING);

    const selectedBusinessesRefs = useRef<Array<string>>([]);
    const markerRefs = useRef<{ [key: string]: google.maps.Marker }>({});

    /**
     * On load or url param change, call api to get the businesses for the specific brand, if the brand id exists.
     */
    useEffect(() => {
        if (!brandId) {
            history("/brands");
            return;
        }

        void getDataForMapForm();
    }, [brandId]);

    /**
     * Because we need the Map to be a pure-component that never re-renders (it will keep focusing on its default center if so),
     * we use refs to the markers to control their opacity, where {opacity: 1} represents businesses that are selected,
     * and {opacity: 0.5} represents businesses that are deselected. Taking into account if the merchant is using the whitelist method
     * or not, so if whitelist = true, turn opacity to 1 for businesses that appear in the list, otherwise if whitelist is false,
     * the businesses that appear in the array should be toggled to opacity 0.5.
     *
     * So, everytime the list of selected businesses or whitelist changes, iterate of the keys of the useRef of the marker refs
     * (business ids), and use the Google Maps built in method to change the opacity.
     *
     */
    useEffect(() => {
        selectedBusinessesRefs.current = createBrandMapForm.businesses;
        Object.keys(markerRefs.current).forEach((markerKey) => {
            if (createBrandMapForm.businesses.indexOf(markerKey) > -1) {
                markerRefs.current[markerKey].setOpacity(1);
            } else {
                markerRefs.current[markerKey].setOpacity(0.5);
            }
        })
    }, [createBrandMapForm.businesses]);

    /**
     * Get the businesses for this brand & save them to state.
     *
     */
    async function getDataForMapForm(): Promise<void> {
        try {
            const brandRes = await new BrandsApi(getConfig(fullToken)).getBrand({
                id: brandId,
            });

            const _businessesRes = await new BrandsApi(getConfig(fullToken)).getBrandBusinesses({
                id: brandId,
                offset: 0,
                limit: 100000,
            });

            setBrand(brandRes);
            setBusinessesRes(_businessesRes);
        } catch (e) {
            dispatch(await addError(e));
        }
    }

    /**
     * Handle onChange event for the image asset upload input.
     *
     * @param key
     */
    function onImageChange(key: keyof Pick<
        CreateBrandMapBodyFrontend,
        "bannerImage" | "promoHero" | "promoBackground" | "promoCartHero" | "promoCartBackground" | "autoMintToastBackground" | "preAutoMintToastBackground"
    >): ChangeEventHandler<HTMLInputElement> {
        return async (e) => {
            const newAsset = (await addURLsToFiles(e.target.files))[0];
            setCreateBrandMapForm({
                ...createBrandMapForm,
                [key]: newAsset,
            });
        }
    }

    /**
     * Handle all text input onChange events.
     *
     * @param key
     */
    function inputOnChange(key: keyof CreateBrandMapBodyFrontend): ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> {
        return (e) => {
            setCreateBrandMapForm({
                ...createBrandMapForm,
                [key]: e.target.value,
            });
        }
    }

    /**
     * Handle map slug input onChange events.
     *
     * @param e
     */
    function slugOnChange(e: React.ChangeEvent<HTMLInputElement>): void {
        setCreateBrandMapForm({
            ...createBrandMapForm,
            slug: e.target.value.toLowerCase().replace(/\W/g, ''),
        });
    }

    /**
     * Handle color theme onChange events.
     *
     * @param theme
     */
    function colorThemeOnChange(theme: BrandMapColorTheme): void {
        setCreateBrandMapForm({
            ...createBrandMapForm,
            colorTheme: theme,
        });
    }

    /**
     * Handle auto mint toast text color change.
     *
     * @param color
     */
    function autoMintToastTextColorOnChange(color: BrandMapColorTheme): void {
        setCreateBrandMapForm({
            ...createBrandMapForm,
            autoMintToastTextColor: color,
        });
    }

    /**
     * Updates the user's login access point
     * @param loginAccessPoint
     */
    function updateLoginAccessPoint(loginAccessPoint: LoginAccessPoint): void {
        setCreateBrandMapForm((_createBrandMapForm) => {
            return {
                ..._createBrandMapForm,
                loginAccessPoint,
            }
        });
    }

    /**
     * Handle main promo onChange events.
     *
     * @param promo
     */
    function mainPromoOnChange(promo: OrderDiscount): void {
        if (createBrandMapForm.mainPromo === promo.id) {
            setCreateBrandMapForm({
                ...createBrandMapForm,
                mainPromo: undefined,
            });
        } else {
            let updatedPromos = createBrandMapForm.promos;

            if (createBrandMapForm.promos.includes(promo.id)) {
                updatedPromos = createBrandMapForm.promos.filter((id) => id !== promo.id);
            }

            setCreateBrandMapForm({
                ...createBrandMapForm,
                promos: updatedPromos,
                mainPromo: promo.id,
            });
        }
    }

    /**
     * Handle regular promo onChange events.
     *
     * @param promo
     */
    function promoOnChange(promo: OrderDiscount): void {
        if (createBrandMapForm.promos.includes(promo.id)) {
            const updatedPromos = createBrandMapForm.promos.filter((id) => id !== promo.id);
            setCreateBrandMapForm({
                ...createBrandMapForm,
                promos: updatedPromos,
            });
        } else {
            setCreateBrandMapForm({
                ...createBrandMapForm,
                promos: [...createBrandMapForm.promos, promo.id],
            });
        }
    }

    /**
     * Submit the new map with the desired status. On completed save, show one of two modals;
     * 1. If merchant chose draft, show them the modal to either "continue editing" or "back to maps".
     * 2. If merchant chose published, show them the modal to either "view the live map" or "back to maps".
     *
     * @param status
     */
    function submitNewMap(status: BrandMapStatus.DRAFT | BrandMapStatus.PUBLISHED): () => Promise<void> {
        return async () => {
            dispatch(incrementLoading());

            try {
                let bannerImage: Asset;
                if (createBrandMapForm.bannerImage) {
                    bannerImage = await new AssetsApi(getConfig(fullToken)).createAsset({
                        asset: createBrandMapForm.bannerImage,
                    });
                }

                let promoHero: Asset;
                if (createBrandMapForm.promoHero) {
                    promoHero = await new AssetsApi(getConfig(fullToken)).createAsset({
                        asset: createBrandMapForm.promoHero,
                    });
                }

                let promoBackground: Asset;
                if (createBrandMapForm.promoBackground) {
                    promoBackground = await new AssetsApi(getConfig(fullToken)).createAsset({
                        asset: createBrandMapForm.promoBackground,
                    });
                }

                let promoCartHero: Asset;
                if (createBrandMapForm.promoCartHero) {
                    promoCartHero = await new AssetsApi(getConfig(fullToken)).createAsset({
                        asset: createBrandMapForm.promoCartHero,
                    });
                }

                let promoCartBackground: Asset;
                if (createBrandMapForm.promoCartBackground) {
                    promoCartBackground = await new AssetsApi(getConfig(fullToken)).createAsset({
                        asset: createBrandMapForm.promoCartBackground,
                    });
                }

                let autoMintToastBackground: Asset;
                if (createBrandMapForm.autoMintToastBackground) {
                    autoMintToastBackground = await new AssetsApi(getConfig(fullToken)).createAsset({
                        asset: createBrandMapForm.autoMintToastBackground,
                    });
                }

                let preAutoMintToastBackground: Asset;
                if (createBrandMapForm.preAutoMintToastBackground) {
                    autoMintToastBackground = await new AssetsApi(getConfig(fullToken)).createAsset({
                        asset: createBrandMapForm.preAutoMintToastBackground,
                    });
                }

                const advertisementBlocks: Array<BrandMapAdvertisementBlocksBody> = await Promise.all(createBrandMapForm.advertisementBlocks.map(async (block) => {
                    const blockImage = block.image;
                    let asset: Asset;
                    if (blockImage) {
                        asset = (isAsset(blockImage)) ?
                            blockImage :
                            await new AssetsApi(getConfig(fullToken)).createAsset({
                                asset: blockImage,
                            });
                    }

                    return {
                        ...block,
                        image: asset?.id,
                    };
                }));

                const res = await new BrandsApi(getConfig(fullToken)).createBrandMap({
                    createBrandMapBody: {
                        name: createBrandMapForm.name,
                        brand: brandId,
                        slug: createBrandMapForm.slug || undefined,
                        bannerImage: bannerImage ? bannerImage.id : undefined,
                        defaultLocation: createBrandMapForm.defaultLocation,
                        status,
                        businesses: createBrandMapForm.businesses,
                        mainPromo: createBrandMapForm.mainPromo,
                        promos: createBrandMapForm.promos,
                        promoHero: promoHero ? promoHero.id : undefined,
                        promoBackground: promoBackground ? promoBackground.id : undefined,
                        promoCartHero: promoCartHero ? promoCartHero.id : undefined,
                        promoCartBackground: promoCartBackground ? promoCartBackground.id : undefined,
                        promoTitle: createBrandMapForm.promoTitle,
                        promoTagline: createBrandMapForm.promoTagline,
                        promoDescription: createBrandMapForm.promoDescription,
                        colorTheme: createBrandMapForm.colorTheme,
                        colorBackground: createBrandMapForm.colorBackground,
                        advertisementBlocks: advertisementBlocks,
                        defaultBusiness: createBrandMapForm.defaultBusiness,
                        autoMintContract: createBrandMapForm.autoMintContract,
                        autoMintToastMessage: createBrandMapForm.autoMintToastMessage,
                        autoMintToastBackground: autoMintToastBackground?.id,
                        description: createBrandMapForm.description,
                        autoMintToastTextColor: createBrandMapForm.autoMintToastTextColor,
                        preAutoMintToastMessage: createBrandMapForm.preAutoMintToastMessage,
                        preAutoMintToastBackground: preAutoMintToastBackground?.id,
                        loginAccessPoint: createBrandMapForm.loginAccessPoint
                    },
                });

                setCreatedBrandMap(res);

                if (res.status === BrandMapStatus.DRAFT) {
                    setShowDraftSuccess(true);
                } else if (res.status === BrandMapStatus.PUBLISHED) {
                    setShowPublishSuccess(true);
                }
            } catch (e) {
                dispatch(await addError(e));
            } finally {
                dispatch(decrementLoading());
            }
        }
    }

    /**
     * Show or hide the modal for editing the default location.
     *
     */
    function toggleAddressModal(): void {
        setShowAddressModal(s => !s);
    }

    /**
     * When merchant confirms changes in the default-location-modal, save new address to the form state. & close the modal.
     *
     * @param newAddress
     */
    function onDoneAddressModel(newAddress: Address): void {
        setShowAddressModal(false);
        setCreateBrandMapForm((_createBrandMapForm) => {
            return {
                ..._createBrandMapForm,
                defaultLocation: newAddress,
            }
        });
    }

    /**
     * Helper for toggling selected businesses; we use a copy of the form data in a ref to maintain our ability to reference &
     * modify their opacity as needed.
     *
     * @param business
     */
    function toggleLocationHelper(business: Business): void {
        const businesses = [...selectedBusinessesRefs.current];
        const selected = businesses.indexOf(business.id) > -1;
        const originalLength = businesses.length;

        if (selected) {
            businesses.splice(businesses.indexOf(business.id), 1);
        } else {
            businesses.push(business.id);
        }

        if (!originalLength && businesses.length === 1) {
            setCreateBrandMapForm((_editBrandMapForm) => {
                return {
                    ..._editBrandMapForm,
                    businesses,
                    defaultBusiness: business.id,
                    defaultLocation: business.address
                }
            });
        } else {
            setCreateBrandMapForm((_editBrandMapForm) => {
                return {
                    ..._editBrandMapForm,
                    businesses,
                }
            });
        }

    }

    /**
     * Updates the form with the selected businesses
     * @param businesses
     */
    function updateSelectedBusinesses(businesses: Array<string>): void {
        setCreateBrandMapForm((_createBrandMapForm) => {
            return {
                ..._createBrandMapForm,
                businesses,
            }
        });
    }

    /**
     * Toggle business as default location
     * @param business
     */
    function toggleDefault(business: Business): void {
        setCreateBrandMapForm((_createBrandMapForm) => {
            return {
                ..._createBrandMapForm,
                defaultLocation: business?.address,
                defaultBusiness: business?.id,
            }
        });
    }

    function toggleViewMode() {
        if (viewMode === ViewMode.PREVIEW) {
            setViewMode(ViewMode.EDITING);
        } else {
            setViewMode(ViewMode.PREVIEW);
        }
    }

    function onRemoveImage(key: keyof Pick<
        EditBrandMapBodyFrontend,
        "bannerImage" | "promoHero" | "promoBackground" | "promoCartHero" | "promoCartBackground" | "autoMintToastBackground" | "preAutoMintToastBackground"
    >): void {
        setCreateBrandMapForm({
            ...createBrandMapForm,
            [key]: undefined,
        })
    }

    /**
     * Resets default location
     */
    function removeDefault(): void {
        setCreateBrandMapForm((_createBrandMapForm) => {
            return {
                ..._createBrandMapForm,
                defaultLocation: undefined,
                defaultBusiness: undefined,
            }
        });
    }

    return (
        <React.Fragment>
            <CreateBrandMapEditAddressModal
                isOpen={showAddressModal}
                onClose={toggleAddressModal}
                address={createBrandMapForm.defaultLocation}
                onDone={onDoneAddressModel}
            />

            <BrandeMapSaveDraftSuccessModal
                isOpen={showDraftSuccess}
                brandMap={createdBrandMap}
                new={true}
            />

            <BrandMapSavePublishedSuccessModal
                isOpen={showPublishSuccess}
                brandMap={createdBrandMap}
                new={true}
            />

            <div
                className={classNames("create-map-page", {
                    "bg-color-editing": viewMode === ViewMode.EDITING
                })}
                style={{backgroundColor: viewMode === ViewMode.PREVIEW ? createBrandMapForm.colorBackground : ""}}
            >
                {(!brand && !businessesRes) ? (
                    <div className="create-map-page_loading">
                        <div className="spinner"/>
                    </div>
                ) : (
                    <React.Fragment>
                        <MapCreationPageHeader
                            onSubmit={submitNewMap(BrandMapStatus.PUBLISHED)}
                            onSaveDraft={submitNewMap(BrandMapStatus.DRAFT)}
                            status={BrandMapStatus.DRAFT}
                            viewMode={viewMode}
                            toggleViewMode={toggleViewMode}
                        />

                        {viewMode === ViewMode.EDITING ? (
                                <div className="create-map-page_content">

                                    <MerchantBrandHandleName
                                        onChange={slugOnChange}
                                        value={createBrandMapForm.slug}
                                    />

                                    <MerchantBrandTitleDescription
                                        value={createBrandMapForm.name}
                                        onChange={inputOnChange}
                                    />

                                    <MerchantBrandDescription
                                        onChange={inputOnChange}
                                        value={createBrandMapForm.description}
                                    />

                                    <MerchantBrandHeaders
                                        topHeader={createBrandMapForm.topHeader}
                                        bottomHeader={createBrandMapForm.bottomHeader}
                                        topHeaderOnChange={inputOnChange}
                                        bottomHeaderOnChange={inputOnChange}
                                    />

                                    <MerchantBrandUploadBannerImage
                                        onChange={onImageChange}
                                        onRemoveImage={onRemoveImage}
                                        image={createBrandMapForm?.bannerImage}
                                    />

                                    <MerchantBrandPageTheme
                                        textColorValue={createBrandMapForm.colorTheme}
                                        onTextColorChange={colorThemeOnChange}
                                        colorBackgroundValue={createBrandMapForm.colorBackground}
                                        onColorBackgroundChange={inputOnChange}
                                    />

                                    <EditBrandMapMainPromoSelection
                                        brand={brand}
                                        formValues={createBrandMapForm}
                                        mainPromoOnChange={mainPromoOnChange}
                                        secondaryPromoOnChange={promoOnChange}
                                    />

                                    {createBrandMapForm?.mainPromo && (
                                        <>
                                            <EditBrandMapMainPromoTitle
                                                formValues={createBrandMapForm}
                                                inputOnChange={inputOnChange}
                                            />

                                            <EditBrandMapMainPromoTagline
                                                formValues={createBrandMapForm}
                                                inputOnChange={inputOnChange}
                                            />

                                            <EditBrandMapMainPromoDescription
                                                formValues={createBrandMapForm}
                                                inputOnChange={inputOnChange}
                                            />

                                            <EditBrandMapMainPromoImages
                                                formValues={createBrandMapForm}
                                                onImageChange={onImageChange}
                                                onRemoveImage={onRemoveImage}
                                            />

                                            <EditBrandMapMainPromoCartImages
                                                formValues={createBrandMapForm}
                                                onImageChange={onImageChange}
                                                onRemoveImage={onRemoveImage}
                                            />
                                        </>
                                    )}

                                    <MerchantBrandLocationSelection
                                        businessesToRender={businessesRes?.brandBusinesses}
                                        defaultAddress={createBrandMapForm.defaultLocation}
                                        selectedBusinesses={createBrandMapForm.businesses}
                                        onToggle={toggleLocationHelper}
                                        onSetDefault={toggleDefault}
                                        updateBusinesses={updateSelectedBusinesses}
                                        onRemoveDefault={removeDefault}
                                    />

                                    <MerchantBrandAdvertisementImages
                                        advertisementBlocks={createBrandMapForm.advertisementBlocks}
                                        onChange={setCreateBrandMapForm}
                                    />

                                    <MerchantBrandUserLoginSettings
                                        updateLoginMode={updateLoginAccessPoint}
                                        loginAccessPoint={createBrandMapForm.loginAccessPoint}
                                    />

                                    <MerchantBrandAutoMint
                                        value={createBrandMapForm.autoMintContract}
                                        onChange={inputOnChange}
                                    />

                                    {createBrandMapForm.autoMintContract && (
                                        <>
                                            <MerchantBrandMapPreAutoMintToast
                                                formValues={createBrandMapForm}
                                                onInputChange={inputOnChange}
                                                onImageChange={onImageChange}
                                                onRemoveImage={onRemoveImage}
                                            />

                                            <MerchantBrandMapPostAutoMintToast
                                                formValues={createBrandMapForm}
                                                onInputChange={inputOnChange}
                                                onImageChange={onImageChange}
                                                onRemoveImage={onRemoveImage}
                                            />

                                            <BrandMapToastTextColor
                                                textColorValue={createBrandMapForm.autoMintToastTextColor}
                                                onTextColorChange={autoMintToastTextColorOnChange}
                                            />
                                        </>

                                    )}

                                    <MerchantBrandButtons
                                        status={BrandMapStatus.DRAFT}
                                        onPreview={toggleViewMode}
                                        onSaveDraft={submitNewMap(BrandMapStatus.DRAFT)}
                                        onPublish={submitNewMap(BrandMapStatus.PUBLISHED)}
                                    />
                                </div>
                            ) :
                            <BrandMapPreviewMode
                                formValues={createBrandMapForm}
                                businesses={businessesRes?.brandBusinesses}
                                brandName={brand.name}
                                brandImage={brand.image}
                                selectedBusinesses={createBrandMapForm.businesses}
                                brandId={brandId}
                                promos={brand.promos}
                            />
                        }
                    </React.Fragment>
                )}
            </div>
        </React.Fragment>
    );
}

export default CreateBrandMapPage;

