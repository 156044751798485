"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.MenuOrderToJSON = exports.MenuOrderFromJSONTyped = exports.MenuOrderFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function MenuOrderFromJSON(json) {
    return MenuOrderFromJSONTyped(json, false);
}
exports.MenuOrderFromJSON = MenuOrderFromJSON;
function MenuOrderFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'currency': json['currency'],
        'customer': json['customer'],
        'business': (0, _1.RestaurantFromJSON)(json['business']),
        'subtotal': json['subtotal'],
        'stripeTotal': json['stripeTotal'],
        'stripeFee': !(0, runtime_1.exists)(json, 'stripeFee') ? undefined : json['stripeFee'],
        'marketplaceWithholding': !(0, runtime_1.exists)(json, 'marketplaceWithholding') ? undefined : json['marketplaceWithholding'],
        'tax': json['tax'],
        'deliveryCharge': json['deliveryCharge'],
        'tipRestaurant': !(0, runtime_1.exists)(json, 'tipRestaurant') ? undefined : json['tipRestaurant'],
        'tipDelivery': !(0, runtime_1.exists)(json, 'tipDelivery') ? undefined : json['tipDelivery'],
        'discounts': !(0, runtime_1.exists)(json, 'discounts') ? undefined : (json['discounts'].map(_1.MenuOrderDiscountFromJSON)),
        'discountCodes': !(0, runtime_1.exists)(json, 'discountCodes') ? undefined : json['discountCodes'],
        'paymentMethodId': !(0, runtime_1.exists)(json, 'paymentMethodId') ? undefined : json['paymentMethodId'],
        'paymentIntentId': !(0, runtime_1.exists)(json, 'paymentIntentId') ? undefined : json['paymentIntentId'],
        'paymentTransferId': !(0, runtime_1.exists)(json, 'paymentTransferId') ? undefined : json['paymentTransferId'],
        'dpay': json['dpay'],
        'vdpay': !(0, runtime_1.exists)(json, 'vdpay') ? undefined : json['vdpay'],
        'onChainDpay': !(0, runtime_1.exists)(json, 'onChainDpay') ? undefined : json['onChainDpay'],
        'isMagicWallet': !(0, runtime_1.exists)(json, 'isMagicWallet') ? undefined : json['isMagicWallet'],
        'transactionHash': !(0, runtime_1.exists)(json, 'transactionHash') ? undefined : json['transactionHash'],
        'dpayTransaction': !(0, runtime_1.exists)(json, 'dpayTransaction') ? undefined : json['dpayTransaction'],
        'dpayRewardsIndustry': json['dpayRewardsIndustry'],
        'dpayRewardsIndustryTransaction': !(0, runtime_1.exists)(json, 'dpayRewardsIndustryTransaction') ? undefined : json['dpayRewardsIndustryTransaction'],
        'dpayRewardsPayment': !(0, runtime_1.exists)(json, 'dpayRewardsPayment') ? undefined : json['dpayRewardsPayment'],
        'dpayRewardsPaymentTransaction': !(0, runtime_1.exists)(json, 'dpayRewardsPaymentTransaction') ? undefined : json['dpayRewardsPaymentTransaction'],
        'dpayFiatAtOrderTime': !(0, runtime_1.exists)(json, 'dpayFiatAtOrderTime') ? undefined : json['dpayFiatAtOrderTime'],
        'experienceRewards': !(0, runtime_1.exists)(json, 'experienceRewards') ? undefined : json['experienceRewards'],
        'experienceRewardsTransaction': !(0, runtime_1.exists)(json, 'experienceRewardsTransaction') ? undefined : json['experienceRewardsTransaction'],
        'status': !(0, runtime_1.exists)(json, 'status') ? undefined : (0, _1.MenuOrderStatusFromJSON)(json['status']),
        'log': !(0, runtime_1.exists)(json, 'log') ? undefined : (json['log'].map(_1.MenuOrderLogFromJSON)),
        'address': (0, _1.AddressFromJSON)(json['address']),
        'distance': !(0, runtime_1.exists)(json, 'distance') ? undefined : json['distance'],
        'restaurantNotes': !(0, runtime_1.exists)(json, 'restaurantNotes') ? undefined : json['restaurantNotes'],
        'deliveryNotes': !(0, runtime_1.exists)(json, 'deliveryNotes') ? undefined : json['deliveryNotes'],
        'scheduledTime': !(0, runtime_1.exists)(json, 'scheduledTime') ? undefined : json['scheduledTime'],
        'marketplacePercentage': !(0, runtime_1.exists)(json, 'marketplacePercentage') ? undefined : json['marketplacePercentage'],
        'marketplaceFee': !(0, runtime_1.exists)(json, 'marketplaceFee') ? undefined : json['marketplaceFee'],
        'serviceFees': !(0, runtime_1.exists)(json, 'serviceFees') ? undefined : (json['serviceFees'].map(_1.MenuOrderServiceFeeFromJSON)),
        'merchantOwed': !(0, runtime_1.exists)(json, 'merchantOwed') ? undefined : json['merchantOwed'],
        'grandTotal': !(0, runtime_1.exists)(json, 'grandTotal') ? undefined : json['grandTotal'],
        'transferAmount': !(0, runtime_1.exists)(json, 'transferAmount') ? undefined : json['transferAmount'],
        'handoff': !(0, runtime_1.exists)(json, 'handoff') ? undefined : (0, _1.HandoffOptionsFromJSON)(json['handoff']),
        'pickupCode': !(0, runtime_1.exists)(json, 'pickupCode') ? undefined : json['pickupCode'],
        'orderItems': (json['orderItems'].map(_1.MenuOrderItemFromJSON)),
        'email': !(0, runtime_1.exists)(json, 'email') ? undefined : json['email'],
        'phoneNumber': !(0, runtime_1.exists)(json, 'phoneNumber') ? undefined : (0, _1.PhoneNumberFromJSON)(json['phoneNumber']),
        'firstName': !(0, runtime_1.exists)(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !(0, runtime_1.exists)(json, 'lastName') ? undefined : json['lastName'],
        'errorMessage': !(0, runtime_1.exists)(json, 'errorMessage') ? undefined : json['errorMessage'],
        'stripeCheckout': !(0, runtime_1.exists)(json, 'stripeCheckout') ? undefined : json['stripeCheckout'],
        'paidToDevour': !(0, runtime_1.exists)(json, 'paidToDevour') ? undefined : json['paidToDevour'],
        'paymentIntent': !(0, runtime_1.exists)(json, 'paymentIntent') ? undefined : json['paymentIntent'],
        'chowlyId': !(0, runtime_1.exists)(json, 'chowlyId') ? undefined : json['chowlyId'],
        'deliveryId': !(0, runtime_1.exists)(json, 'deliveryId') ? undefined : json['deliveryId'],
        'discountSelected': !(0, runtime_1.exists)(json, 'discountSelected') ? undefined : json['discountSelected'],
        'discountNullify': !(0, runtime_1.exists)(json, 'discountNullify') ? undefined : json['discountNullify'],
        'callbackComplete': !(0, runtime_1.exists)(json, 'callbackComplete') ? undefined : json['callbackComplete'],
        'coinbaseChargeId': !(0, runtime_1.exists)(json, 'coinbaseChargeId') ? undefined : json['coinbaseChargeId'],
        'isCoinbase': !(0, runtime_1.exists)(json, 'isCoinbase') ? undefined : json['isCoinbase'],
        'exceededSlippage': !(0, runtime_1.exists)(json, 'exceededSlippage') ? undefined : json['exceededSlippage'],
        'refunds': !(0, runtime_1.exists)(json, 'refunds') ? undefined : (json['refunds'].map(_1.OrderRefundFromJSON)),
    };
}
exports.MenuOrderFromJSONTyped = MenuOrderFromJSONTyped;
function MenuOrderToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'currency': value.currency,
        'customer': value.customer,
        'business': (0, _1.RestaurantToJSON)(value.business),
        'subtotal': value.subtotal,
        'stripeTotal': value.stripeTotal,
        'stripeFee': value.stripeFee,
        'marketplaceWithholding': value.marketplaceWithholding,
        'tax': value.tax,
        'deliveryCharge': value.deliveryCharge,
        'tipRestaurant': value.tipRestaurant,
        'tipDelivery': value.tipDelivery,
        'discounts': value.discounts === undefined ? undefined : (value.discounts.map(_1.MenuOrderDiscountToJSON)),
        'discountCodes': value.discountCodes,
        'paymentMethodId': value.paymentMethodId,
        'paymentIntentId': value.paymentIntentId,
        'paymentTransferId': value.paymentTransferId,
        'dpay': value.dpay,
        'vdpay': value.vdpay,
        'onChainDpay': value.onChainDpay,
        'isMagicWallet': value.isMagicWallet,
        'transactionHash': value.transactionHash,
        'dpayTransaction': value.dpayTransaction,
        'dpayRewardsIndustry': value.dpayRewardsIndustry,
        'dpayRewardsIndustryTransaction': value.dpayRewardsIndustryTransaction,
        'dpayRewardsPayment': value.dpayRewardsPayment,
        'dpayRewardsPaymentTransaction': value.dpayRewardsPaymentTransaction,
        'dpayFiatAtOrderTime': value.dpayFiatAtOrderTime,
        'experienceRewards': value.experienceRewards,
        'experienceRewardsTransaction': value.experienceRewardsTransaction,
        'status': (0, _1.MenuOrderStatusToJSON)(value.status),
        'log': value.log === undefined ? undefined : (value.log.map(_1.MenuOrderLogToJSON)),
        'address': (0, _1.AddressToJSON)(value.address),
        'distance': value.distance,
        'restaurantNotes': value.restaurantNotes,
        'deliveryNotes': value.deliveryNotes,
        'scheduledTime': value.scheduledTime,
        'marketplacePercentage': value.marketplacePercentage,
        'marketplaceFee': value.marketplaceFee,
        'serviceFees': value.serviceFees === undefined ? undefined : (value.serviceFees.map(_1.MenuOrderServiceFeeToJSON)),
        'merchantOwed': value.merchantOwed,
        'grandTotal': value.grandTotal,
        'transferAmount': value.transferAmount,
        'handoff': (0, _1.HandoffOptionsToJSON)(value.handoff),
        'pickupCode': value.pickupCode,
        'orderItems': (value.orderItems.map(_1.MenuOrderItemToJSON)),
        'email': value.email,
        'phoneNumber': (0, _1.PhoneNumberToJSON)(value.phoneNumber),
        'firstName': value.firstName,
        'lastName': value.lastName,
        'errorMessage': value.errorMessage,
        'stripeCheckout': value.stripeCheckout,
        'paidToDevour': value.paidToDevour,
        'paymentIntent': value.paymentIntent,
        'chowlyId': value.chowlyId,
        'deliveryId': value.deliveryId,
        'discountSelected': value.discountSelected,
        'discountNullify': value.discountNullify,
        'callbackComplete': value.callbackComplete,
        'coinbaseChargeId': value.coinbaseChargeId,
        'isCoinbase': value.isCoinbase,
        'exceededSlippage': value.exceededSlippage,
        'refunds': value.refunds === undefined ? undefined : (value.refunds.map(_1.OrderRefundToJSON)),
    };
}
exports.MenuOrderToJSON = MenuOrderToJSON;
