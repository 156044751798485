"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.MenuOrderOwnToJSON = exports.MenuOrderOwnFromJSONTyped = exports.MenuOrderOwnFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function MenuOrderOwnFromJSON(json) {
    return MenuOrderOwnFromJSONTyped(json, false);
}
exports.MenuOrderOwnFromJSON = MenuOrderOwnFromJSON;
function MenuOrderOwnFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'business': (0, _1.RestaurantResponseForMenuOrderOwnFromJSON)(json['business']),
        'subtotal': json['subtotal'],
        'stripeTotal': json['stripeTotal'],
        'serviceFees': !(0, runtime_1.exists)(json, 'serviceFees') ? undefined : (json['serviceFees'].map(_1.MenuOrderServiceFeeFromJSON)),
        'tax': json['tax'],
        'deliveryCharge': json['deliveryCharge'],
        'tipDelivery': !(0, runtime_1.exists)(json, 'tipDelivery') ? undefined : json['tipDelivery'],
        'discounts': !(0, runtime_1.exists)(json, 'discounts') ? undefined : (json['discounts'].map(_1.MenuOrderDiscountFromJSON)),
        'transactionHash': !(0, runtime_1.exists)(json, 'transactionHash') ? undefined : json['transactionHash'],
        'status': !(0, runtime_1.exists)(json, 'status') ? undefined : (0, _1.MenuOrderStatusFromJSON)(json['status']),
        'address': (0, _1.AddressFromJSON)(json['address']),
        'handoff': !(0, runtime_1.exists)(json, 'handoff') ? undefined : (0, _1.HandoffOptionsFromJSON)(json['handoff']),
        'pickupCode': !(0, runtime_1.exists)(json, 'pickupCode') ? undefined : json['pickupCode'],
        'orderItems': (json['orderItems'].map(_1.MenuOrderItemFromJSON)),
        'callbackComplete': json['callbackComplete'],
        'exceededSlippage': !(0, runtime_1.exists)(json, 'exceededSlippage') ? undefined : json['exceededSlippage'],
    };
}
exports.MenuOrderOwnFromJSONTyped = MenuOrderOwnFromJSONTyped;
function MenuOrderOwnToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'business': (0, _1.RestaurantResponseForMenuOrderOwnToJSON)(value.business),
        'subtotal': value.subtotal,
        'stripeTotal': value.stripeTotal,
        'serviceFees': value.serviceFees === undefined ? undefined : (value.serviceFees.map(_1.MenuOrderServiceFeeToJSON)),
        'tax': value.tax,
        'deliveryCharge': value.deliveryCharge,
        'tipDelivery': value.tipDelivery,
        'discounts': value.discounts === undefined ? undefined : (value.discounts.map(_1.MenuOrderDiscountToJSON)),
        'transactionHash': value.transactionHash,
        'status': (0, _1.MenuOrderStatusToJSON)(value.status),
        'address': (0, _1.AddressToJSON)(value.address),
        'handoff': (0, _1.HandoffOptionsToJSON)(value.handoff),
        'pickupCode': value.pickupCode,
        'orderItems': (value.orderItems.map(_1.MenuOrderItemToJSON)),
        'callbackComplete': value.callbackComplete,
        'exceededSlippage': value.exceededSlippage,
    };
}
exports.MenuOrderOwnToJSON = MenuOrderOwnToJSON;
