import React, {ReactElement} from "react";
import {EditBrandMapBodyFrontend} from "../BrandMapFormValues";
import useWindowSize from "../../../../utils/hooks/useWindowSize";
import {generateBackgroundUrl} from "../../../../utils/brands/generateImageUrl";

interface Props {
    formValues: EditBrandMapBodyFrontend;
}

function PreviewRestaurantHeader(props: Props): ReactElement {
    const windowSize = useWindowSize()[0];

    return (
        <div className="restaurant-map-landing_header">
            {(props?.formValues?.bannerImage) && (
                <div
                    className="restaurant-map-landing_header_banner"
                    style={{backgroundImage: `url(${generateBackgroundUrl(props.formValues.bannerImage)})`}}
                >
                    <div className="background-triangle left"
                         style={{ borderLeft: `${windowSize/2}px solid ${props.formValues.colorBackground}`}}
                    />

                    <div className="background-triangle right"
                         style={{ borderRight: `${windowSize/2}px solid ${props.formValues.colorBackground}`}}
                    />

                    <svg className="restaurant-map-landing_header_banner_angle-lines" width={windowSize} height="89" viewBox="0 0 1280 89" fill="none" xmlns="http://www.w3.org/2000/svg"  preserveAspectRatio="none">
                        <line x1="0.134699" y1="1.00911" x2="640.135" y2="88.0091" strokeWidth="2"/>
                        <line y1="-1" x2="645.886" y2="-1"
                              transform="matrix(-0.990887 0.134699 0.134699 0.990887 1280 2)" strokeWidth="2"/>
                    </svg>
                </div>
            )}
            <div
                className="restaurant-map-landing_header_text-content"
                style={{background: props.formValues.colorBackground}}
            >
                <h1>{props.formValues.name.length ? props.formValues.name : "THIS IS A TITLE DESCRIPTION"}</h1>
                <p className={props.formValues.description ? "has-description" : ""}>
                    Order from DevourGO
                </p>
                {props.formValues.description?.length > 0 && (
                    <div className="restaurant-map-landing_header_text-content_description">
                        {props.formValues.description}
                    </div>
                )}
                <div
                    className="restaurant-map-landing_header_text-content_scroll-container"
                    onClick={() => document.getElementById(props.formValues?.mainPromo ? "restaurant-map-landing_content_claim-rewards"
                        : "restaurant-map-landing_content_locations").scrollIntoView({
                        behavior: "smooth",
                        block: "start"
                    })}
                >
                    <img
                        src={process.env.REACT_APP_CDN_URL + "/images/double-arrow-down-gradient2.svg"}
                         alt="arrow"
                    />
                </div>
            </div>
        </div>
    );
}

export default PreviewRestaurantHeader;
